<template>
  <div class="ImageSizeSelectMenu">
    <!-- Base size -->
    <DotMenuItem
      :text="getSizeText(image.base)"
      :itemID="'base'"
      size="small"
      @dot-item-click="onDotItemClick" />

    <!-- Extra sizes -->
    <template v-if="image.sizes">
      <DotMenuItem
        v-for="size in image.sizes"
        :key="size.name"
        :text="getSizeText(size)"
        :itemID="size.name"
        size="small"
        @dot-item-click="onDotItemClick" />
    </template>
  </div>
</template>

<script>
import DotMenuItem from '@/components/DotMenu/DotMenuItem'

export default {
  name: 'ImageSizeSelectMenu',
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getSizeText(size) {
      return `${ this.mixGetSizeWordbook(size.name) } - ${ size.maxSize || size.minSize }px`
    },
    onDotItemClick(itemID) {
      this.$emit('pass-to-parent', { name: 'size-click', value: itemID })
      this.$emit('close-menu')
    },
  },
  components: {
    DotMenuItem,
  },

}
</script>

<style lang="stylus" scoped>
  .ImageSizeSelectMenu
    display block
</style>
